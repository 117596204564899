module.exports = {
    env: {
        port: 8089,
    },
    url: {
	cors: "http://www.easy100.com,http://easy100.com",  // 前端地址
        hook: "http://api.easy100.com:8088",  // API地址
        video: "https://zkt.jd100.com/智课堂宣传片.mp4", // 智课堂视频介绍
        appUrl: 'http://service.jd100.com/cgi-bin/phone/',
    },
    // 申请合作发邮件限制
    redis: {
        host: "127.0.0.1",
        port: 6379,
        options: {
            password: "",
            timeout: 3000,
            expire: 600,
        }
    },
    spam: {
        limit: 3,
        seconds: 60,
    },
    // /etc/hosts
    // 192.168.1.250 smtp.jiandan100.cn mail.jiandan100.cn
    // @file: node_modules/nodemailer/lib/well-known/services.json
    // +
    /*
"jiandan100": {
	"host": "smtp.jiandan100.cn",
	"port": 25,
	"secure": false
}
     */
    email: {
        service: "jiandan100",
        host: 'mail.jiandan100.cn',
        port: 25,
        user: 'xiaojian',
        pass: 'EExj7u8i9o',
        from: 'xiaojian@jiandan100.cn',
        // to: 'wangchunyan1@jiandan100.cn',
        to: 'zl@jiandan100.cn;hemiao@jiandan100.cn',
        secure: false,
    }
};
