/**
 * create by wangchunyan1 on 2019/6/25
 */

import { formatParams } from "./tools";

export async function post(url, data) {
  return await fetchAsync(url, "POST", data);
}

export async function get(url) {
  return await fetchAsync(url, "GET");
}

let fetchAsync = function(url, method, data) {
  try {
    let req = {
      method: method,
      headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
      mode: "cors"
    };

    if (method === 'GET') {
      req.headers = { "Content-Type": "application/json; charset=UTF-8" };
    } else {
      let params = formatParams(data);
      req.body = params;
    }

    // req.body = JSON.stringify(data);
    return fetch(url, req).then(function(response) {
      if (!response.ok) {

        return response.json().then(function(errorInfo) {
          let error = new Error("serverError");
          error.source = "server";
          error.error = errorInfo;
          throw error;
        });
      }
      return response.json().then(function(res) {
        if (res.hasOwnProperty("code")) {
          if (res.code === 0) {
            res.msg = "获取代理政策成功!";
          }
        } else {
          res.msg = "邮件发送失败!";
        }
        return res;
      });
    }).catch(function(err) {
      if (!err.source) {
        err.source = "network";
      //  alert("请检查网络连接是否正常！");
      }
      throw err;
    });
  } catch (e) {
    console.log(e);
  }
};
