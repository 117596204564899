/**
 * create by wangchunyan1 on 2019/6/14
 */
import React from "react";
import "./footer.less";

import hotLine from '../images/hotline.png'

const Footer = () => (
  <div className='footer'>
    <div className='box'>
      <dl>
        <dt>
          <p>
            北京简单科技有限公司 简单智课堂
          </p>
          <p>
            copyright 2007-2016 jd100.com,All Rights Reserved 京ICP证070589号 <a href='https://beian.miit.gov.cn/' target='_blank' rel="noopener noreferrer">京ICP备08103216号-13 </a>
          </p>
          <p>
            京公网安备 11010802022352号 安全联盟
          </p>
          <div className='imageBox'>
            <a href='https://credit.cecdc.com/CX20160503015018500197.html' target='_blank' rel="noopener noreferrer"><label className='chengxin'/></a>
            <a href='https://v.yunaq.com/certificate?domain=www.jd100.com&from=label&code=90030' target='_blank' rel="noopener noreferrer"><label
              className='hangye'/></a>
          </div>
        </dt>
        <dd>
          <div className='contactBox'>
            <label className='contact'/>
            <span>咨询<br/>热线</span>
          </div>
          <div className='contactWay'>
            <p>
              <img alt='' src={hotLine} />
            </p>
            <label>周一~周五: 9:00 ~ 19:00</label>
          </div>
        </dd>
      </dl>
    </div>
  </div>
);
export default Footer;
