import { Link } from "gatsby";
import React from "react";
import Message from './message/message'
import "./header.less";
import { currentUrlToActiveTab, getTerminalType, isInWindow } from "../utils/tools";
import {WebTitle} from "../utils/constant";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeItem: "home",
      isShowMessage:false
    };
  }

  componentWillMount() {
    if (isInWindow()) {
      document.title = WebTitle.title;
      let terminalType = getTerminalType();
      let urlArr = window.location.pathname.split("/");
      let temp = [];
      // eslint-disable-next-line
      urlArr.filter((item) => {
        if (item) {
          temp.push(item);
        }
      });
      let tempIndex0 = temp[0];
      if (tempIndex0.charAt(tempIndex0.length - 1) === "p" && terminalType === "yd") {//移动设备打开pc页面
        let t = tempIndex0.substring(0, tempIndex0.length - 1);
        temp[0] = t + "y";
        window.location.href = "/" + temp.join("/");
      }
    }
  }

  componentDidMount() {
    if(isInWindow()){
      let url = window.location.href;
      let activeItem = `${currentUrlToActiveTab(url)}`;
      this.setState({
        activeItem: activeItem
      });
    }
  }

  willDone(item,e) {
    switch (item) {
      case "home":
        this.navigatorTo(item);
        break;
      case "teacher":
        this.navigatorTo(item);
        break;
      case "master":
        this.navigatorTo(item);
        break;
      case "operation":
        e.preventDefault();
        this.showAlert();
        break;
      case "news":
        this.navigatorTo(item);
        break;
      case "download":
        this.navigatorTo(item)
        break;
      default:
        this.navigatorTo("404");
    }
  }

  navigatorTo(toUrl) {
    this.setState({
      activeItem: toUrl
    });
  }

  showAlert() {
    this.setState({
      isShowMessage:true
    });
    // eslint-disable-next-line
    setTimeout(()=>{
      this.setState({
        isShowMessage:false
      })
    },3000);
  }
  render() {
    let activeItem = this.state.activeItem;
    return (
      <div className='header'>
        <label className='logo'/>
        <ul className='nav'>
          <li><Link to='/homep' key={'home'} className={activeItem === "home" ? "active" : "normal"}
                    onClick={this.willDone.bind(this, "home")}>首页</Link></li>
          <li><Link to='/teacherp/' key={'teacher'} className={activeItem === "teacher" ? "active" : "normal"}
                    onClick={this.willDone.bind(this, "teacher")}>我是老师</Link></li>
          <li><Link to='/masterp/' key={'master'} className={activeItem === "master" ? "active" : "normal"}
                    onClick={this.willDone.bind(this, "master")}>我是校长</Link></li>
          <li><Link to='' key={'operation'} className={activeItem === "operation" ? "active" : "normal"}
                    onClick={this.willDone.bind(this, "operation")}>申请合作</Link></li>
          <li><Link key={'news'} to='/newsp/' className={activeItem === "news" ? "active" : "normal"}
                    onClick={this.willDone.bind(this, "news")}>新闻中心</Link></li>
          <li><Link to='/downloadp/' key={'download'} className={activeItem === "download" ? "active" : "normal"}
                    onClick={this.willDone.bind(this, "download")}>下载中心</Link></li>
        </ul>
        {
          this.state.isShowMessage && <Message info="内容正在维护，敬请期待！"/>
        }
      </div>
    );
  }
}


export default Header;
