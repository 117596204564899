/**
 ** Created By liuqi on 2019-08-07
 */
import React from "react";
import QRCode from "qrcode.react";
import Layout from "../components/layout";

import picPcTeacher from "../images/pic_pcTeacher.png";
import picPcStudent from "../images/pic_pcStudent.png";
import picPadTeacher from "../images/pic_padTeacher.png";
import picAndroidStudent from "../images/pic_androidStudent.png";
import iconLeftArrowNormal from "../images/icon_leftArrow_normal.png";
import iconLeftArrowActive from "../images/icon_leftArrow_active.png";

import "./downloadp.less";

import { get } from "../utils/apis";

import config from "../../config/config";
import { isInWindow } from "../utils/tools";

/*tab切换选项*/
const DownTab = {
  pcTeacher: "pcTeacher",
  pcStudent: "pcStudent",
  padTeacher: "padTeacher",
  androidStudent: "androidStudent"
};

class DownloadP extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ydUrl: "",
      activeTab: DownTab.pcTeacher,
      pcTeacher: {},
      pcStudent: {},
      padTeacher: {},
      screenPlay: {},
      androidStudent: {},
      activeTabIndex: 0
    };
    this.carouselUlRef = React.createRef();
    this.carouselIdxRef = React.createRef();
    this.child = [];
  }


  componentWillMount() {
    this.init();
    if (isInWindow()) {
      this.setState({
        ydUrl: `${window.location.origin}/downloady`
      });
    }
  }

  componentDidMount() {
    this.initDownList();
  }

  init() {
    this.getStudentDownloadInfo().catch(() => {
    });
    this.getTeacherDownloadInfo().catch(() => {
    });
    this.getScreenPlayDownloadInfo().catch(() => {
    });
    this.getAppDownloadInfo().catch(() => {
    });
    this.getTeacherAppDownloadInfo().catch(() => {
    });
  }

  /*下载学生端*/
  async getStudentDownloadInfo() {
    const result = await get(`${config.url.hook}/software?t=student`);
    if (result.code === 0) {
      this.setState({
        pcStudent: result.data
      });
    }
  }

  initDownList() {
    const carouselUlRef = this.carouselUlRef.current;
    this.child = Array.from(carouselUlRef.children);
    if (!this.child.length) {
      return;
    }
    this.child = Array.from(carouselUlRef.children);
    this.rowPic(0);
  }

  /**
   * 排列图片
   */
  rowPic(currentIdx) {
    for (let i = 0, len = this.child.length; i < len; i++) {
      this.child[i].style.transition = "all ease .8s";
      this.child[i].style.transform = `translateX(${(i - currentIdx) * 100}%)`;
    }
  }


  /*下载老师端*/
  async getTeacherDownloadInfo() {
    const result = await get(`${config.url.hook}/software?t=teacher`);
    if (result.code === 0) {
      this.setState({
        pcTeacher: result.data
      });
    }
  }

  /*下载投屏插件*/
  async getScreenPlayDownloadInfo() {
    const result = await get(`${config.url.hook}/software?t=screenPlay`);
    if (result.code === 0) {
      this.setState({
        screenPlay: result.data
      });
    }
  }

  /*下载学生安卓端*/
  async getAppDownloadInfo() {
    const result = await get(`${config.url.hook}/downloadApp?type=student`);
    if (result.code === 0) {
      this.setState({
        androidStudent: result.data
      });
    }
  }

  /*下载老师pad*/
  async getTeacherAppDownloadInfo() {
    const result = await get(`${config.url.hook}/downloadApp?type=teacher`);
    if (result.code === 0) {
      this.setState({
        padTeacher: result.data
      });
    }
  }

  /*切换tab*/
  toggleDownTab = (tab) => {
    if (this.state.activeTab === tab) {
      return;
    }
    let tabIndex = Object.values(DownTab).findIndex((item) => item === tab);
    this.setState({
      activeTab: tab,
      activeTabIndex: tabIndex
    });
    this.rowPic(tabIndex);
  };

  /*前后切换*/
  lastOrNext = (isLastOrNext) => {
    const { activeTab, activeTabIndex } = this.state;
    let tempIndex = activeTabIndex;
    let tempTab = activeTab;
    let DownTabArr = Object.values(DownTab);
    let len = DownTabArr.length;
    if (isLastOrNext === "last") {
      tempIndex = tempIndex <= 0 ? 0 : --tempIndex;
    } else if (isLastOrNext === "next") {
      tempIndex = tempIndex >= len - 1 ? len - 1 : ++tempIndex;
    }
    tempTab = DownTabArr[tempIndex];
    this.setState({
      activeTab: tempTab,
      activeTabIndex: tempIndex
    });
    this.rowPic(tempIndex);
  };

  render() {
    const { activeTab, activeTabIndex, pcTeacher, pcStudent, padTeacher, screenPlay, androidStudent } = this.state;
    const downTabLen = Object.values(DownTab).length;
    return (
      <Layout>
        <section className='downloadBoxP'>
          <div className='downHeader' ref={this.carouselIdxRef}>
            <span className={`normal ${activeTab === DownTab.pcTeacher && "active"}`}
                  onClick={this.toggleDownTab.bind(this, DownTab.pcTeacher)}>PC 教师端</span>
            <span className={`normal ${activeTab === DownTab.pcStudent && "active"}`}
                  onClick={this.toggleDownTab.bind(this, DownTab.pcStudent)}>PC 学生端</span>
            <span className={`normal ${activeTab === DownTab.padTeacher && "active"}`}
                  onClick={this.toggleDownTab.bind(this, DownTab.padTeacher)}>PAD 教师端</span>
            <span className={`normal ${activeTab === DownTab.androidStudent && "active"}`}
                  onClick={this.toggleDownTab.bind(this, DownTab.androidStudent)}>手机学生端</span>
          </div>
          <div className='content'>
            {
              activeTabIndex <= 0 ?
                <div className='lastPage'>
                  <img src={iconLeftArrowNormal}/>
                </div>
                :
                <div className='lastPage' onClick={this.lastOrNext.bind(this, "last")}>
                  <img src={iconLeftArrowActive}/>
                </div>
            }
            <div className={"downloadList"} ref={this.carouselUlRef}>
              <div className={"downloadBoxPc"}>
                <div className={"downloadItem"}>
                  <div className='itemText animateText'>
                    <h3 className='downloadTitle'>PC 教师端</h3>
                    <p className='subTitle'>学习变简单，教育更公平</p>
                    <a href={pcTeacher.InstallAddr} className='downloadBtn'>点击下载</a>
                    <p className='downloadInfo'>适用于：{pcTeacher.OS}</p>
                    <p className='downloadInfo'>版本：{pcTeacher.MainVersion}</p>
                  </div>
                  <img src={picPcTeacher} alt='老师' className='animateImg'/>
                </div>
              </div>

              <div className={"downloadBoxPc"} style={{transform: "translateX(100%)"}}>
                <div className={"downloadItem"}>
                  <div className='itemText animateText'>
                    <h3 className='downloadTitle'>PC 学生端</h3>
                    <p>学习变简单，教育更公平</p>
                    <a href={pcStudent.InstallAddr} className='downloadBtn'>点击下载</a>
                    <p className='downloadInfo'>适用于：{pcStudent.OS}</p>
                    <p className='downloadInfo'>版本：{pcStudent.MainVersion}</p>
                  </div>
                  <img src={picPcStudent} alt='老师' className='animateImg'/>
                </div>
              </div>
              <div className={"downloadBoxPc"} style={{transform: "translateX(200%)"}}>
                <div className={"downloadItem"}>
                  <div className='itemText animateText'>
                    <h3 className='downloadTitle'>PAD 教师端</h3>
                    <p>学习变简单，教育更公平</p>
                    <p className='downloadInfo'>版本：{padTeacher.versionName}</p>
                    <div className='qrCode'>
                      <QRCode size={110}
                              value={this.state.ydUrl}/>
                    </div>
                    <p className='downloadInfo'>请用安卓PAD扫描二维码下载</p>
                    <h4 className='downPlugin'>投屏插件</h4>
                    <p className='downloadInfo'>安装在教室大屏上，PAD教师端扫码即可连接大屏</p>
                    <a href={screenPlay.InstallAddr} className='downloadBtn pluginBtn'>点击下载</a>
                  </div>
                  <img src={picPadTeacher} alt='老师' className='animateImg'/>
                </div>
              </div>
              <div className={"downloadBoxPc"} style={{transform: "translateX(300%)"}}>
                <div className={"downloadItem"}>
                  <div className='itemText animateText'>
                    <h3 className='downloadTitle'>安卓手机学生端</h3>
                    <p>学习变简单，教育更公平</p>
                    <p className='downloadInfo'>版本：{androidStudent.versionName}</p>
                    <div className='qrCode'>
                      <QRCode size={110}
                              value={'https://et-jery.oss-cn-beijing.aliyuncs.com/js/app-release.apk'}/>
                    </div>
                    <p className='downloadInfo'>请用安卓手机扫描二维码下载
                    </p>
                  </div>
                  <img src={picAndroidStudent} alt='老师' className='animateImg'/>
                </div>
              </div>
            </div>
            {
              activeTabIndex >= downTabLen - 1 ?
                <div className='nextPage'>
                  <img src={iconLeftArrowNormal}/>
                </div>
                :
                <div className='nextPage' onClick={this.lastOrNext.bind(this, "next")}>
                  <img src={iconLeftArrowActive}/>
                </div>
            }
          </div>
        </section>
      </Layout>
    );
  }
}

export default DownloadP;
