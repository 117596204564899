/**
 * create by wangchunyan1 on 2019/7/3
 */
// import { Link } from "gatsby";
import React from "react";
import "./message.less";

class message extends React.Component {

  render() {
    return(
      <div className='messageBox'>
        <div className='message'>{this.props.info}</div>
      </div>
    )
  }
}

export default message;
