/**
 * create by wangchunyan1 on 2019/8/9
 */

export const Video = {
  "Trailer": "https://zkt.jd100.com/智课堂宣传片.mp4",
  "JieGouKe": "https://zkt.jd100.com/【8.2】解构课视频.mp4",
  "TeacherInterview": "https://zkt.jd100.com/【6.2】老师采访.mp4",
  "StudentInterview": "https://zkt.jd100.com/【6.3】学生访谈.mp4",
  "ELunChun": "https://zkt.jd100.com/【6.1】鄂伦春中学教学案例.mp4"
};

export const WebTitle = {
  title:'简单智慧课堂'
};
